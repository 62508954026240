<template>
  <div>
    <b-row>
      <b-col :cols="value ? 8 : 12">
        <slot />
      </b-col>
      <b-col v-if="value" cols="4">
        <b-card header-class="p-0">
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-primary"
              @click="$emit('input', false)"
            >
              <span>
                Close
                <feather-icon icon="ChevronRightIcon" />
              </span>
            </b-button>
            <h4 class="pr-2">
              <strong>
                {{ title }}
              </strong>
            </h4>
          </template>
          <div>
            <slot name="details" />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Details',
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: Boolean,
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>
